.background-style {
    &--navy {
        background-color: $navy;
    }

    &--pewter1 {
        background-color: $pewter1;
    }

    &--pewter1-with-satin-brass {
        background-color: $pewter1;
        border-top: 10px solid $satin-brass;
    }

    &--pewter2 {
        background-color: $pewter2;
    }

    &--satin-brass {
        background-color: $satin-brass;
    }

    &--satin-brass-borders {
        border-top: 2px solid $satin-brass;
        border-bottom: 2px solid $satin-brass;
    }

    &--smoke {
        background-color: $smoke;
    }
}

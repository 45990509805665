@import "./variables";

// if you change the measurement units for these, make sure
// letterSpacingAdjustment still works in ImageTimes
$heading-letter-spacing: 0.09375em;
$h2-size: 32px;

@mixin button-reset {
    display: inline-block;
    appearance: none;
    padding: 0;
    border: 0;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: 1em;
    cursor: pointer;
}

@mixin secondary-all-caps {
    font-family: $secondary-font-family;
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
}

@mixin primary-all-caps-bold {
    font-family: $base-font-family;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
}

@mixin button-all-caps {
    @include secondary-all-caps;
    font-family: var(--button--font-family, $secondary-font-family);
}

@mixin button {
    @include button-reset;
    @include button-all-caps;
    position: relative;
    padding: 11px 30px;
    background-color: var(--button--bg-color, $satin-brass);
    border: 1px solid transparent;
    color: var(--button--font-color, $black);
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    transition:
        background-color 0.2s,
        border-color 0.2s,
        color 0.2s;

    &:disabled,
    &.disabled {
        cursor: default;
        pointer-events: none;

        &.loading {
            color: transparent;
        }
    }

    &.loading {
        color: transparent;
    }

    &:focus-visible,
    &:active {
        outline: 1px solid $pewter3;
    }
}

@mixin button-primary {
    @include button;

    background-color: var(--button-primary--bg-color, $satin-brass);
    border-color: var(--button-primary--border-color, $satin-brass);

    &:hover:not(.loading) {
        background-color: var(
            --button-primary--hover-bg-color,
            $satin-brass-hover
        );
        border-color: var(
            --button-primary--hover-border-color,
            $satin-brass-hover
        );
    }

    &:disabled,
    &.disabled {
        background-color: var(--button-primary--disabled-bg-color, $pewter1);
        border-color: var(--button-primary--disabled-border-color, $pewter1);
        color: var(--button-primary--disabled-font-color, $pewter3);
    }
}

@mixin button-secondary {
    @include button;

    background-color: transparent;
    border-color: $black;
    color: var(--button-secondary--font-color, $black);

    &:hover:not(.loading) {
        background-color: $black;
        color: $white;
    }

    &:disabled,
    &.disabled {
        background-color: transparent;
        border-color: var(--button-secondary--disabled-border-color, $pewter1);
        color: var(--button-secondary--disabled-font-color, $pewter3);
    }
}

@mixin button-text {
    @include button;

    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    text-underline-offset: var(--button-text--underline-offset, auto);
    padding-left: 0;
    padding-right: 0;
    color: inherit;

    @media ($below-mobile) {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &:disabled,
    &.disabled {
        color: $pewter3;
    }
}

// This comment might fix a nextjs bug. Do not remove, please https://github.com/vercel/next.js/issues/16630
@import "../node_modules/modern-normalize/modern-normalize.css";

@import "./variables";
@import "./background-styles";

:root {
    --flexihero-text-light: #{$white};
    --flexihero-text-dark: #{$black};
    --flexihero-padding: 0;
    --flexihero-color1: #{$navy};
    --flexihero-color1-dark-mode: #{$pewter2};
    --flexihero-color2: #{$smoke};
    --flexihero-color2-dark-mode: #{$pewter1};
    --flexihero-color3: #{$satin-brass};
    --flexihero-color3-dark-mode: #{$smoke};
    --flexihero-color4: #{$pewter1};
    --flexihero-color4-dark-mode: #{$smoke};
    --flexihero-color5: #{$pewter2};
    --flexihero-color5-dark-mode: #{$smoke};
    --flexihero-color6: #{$gold};
    --flexihero-color6-dark-mode: #{$pewter2};
    --flexihero-color7: #{$green};
    --flexihero-color7-dark-mode: #{$pewter1};
    --flexihero-color8: #{$blue};
    --flexihero-color8-dark-mode: #{$smoke};
    --flexihero-color9: #{$pink};
    --flexihero-color9-dark-mode: #{$smoke};
    --flexihero-color10: #{$beige};
    --flexihero-color10-dark-mode: #{$smoke};
    --flexihero-color11: #{$silver};
    --flexihero-color11-dark-mode: #{$smoke};
    --flexihero-color12: #{$darkSilver};
    --flexihero-color12-dark-mode: #{$smoke};

    --text-input-height: 44px;
    --text-input-padding: 15px;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;

    @media ($below-mobile) {
        font-size: 14px;
    }
}

body [data-reach-dialog-overlay] {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

@page {
    margin: 0.75in 1.25in;
}

@import "./typography-variables";
@import "./typography";

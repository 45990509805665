p,
.p {
    margin: 0 0 1em;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0.65em 0;
    font-family: $base-font-family;
    font-weight: 300;
}

h1,
.h1,
h2,
.h2,
h4,
.h4 {
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

h3,
.h3,
h6,
.h6 {
    letter-spacing: 0;
    text-transform: none;
}

h1,
.h1 {
    font-size: 48px;
    line-height: 1.25;

    @media ($below-tablet) {
        font-size: 40px;
    }

    @media ($below-mobile) {
        font-size: 32px;
    }
}

h2,
.h2 {
    font-size: $h2-size;
    line-height: 1.25;

    @media ($below-mobile) {
        font-size: 24px;
    }
}

h3,
.h3 {
    font-size: 32px;
    line-height: 1.25;

    @media ($below-mobile) {
        font-size: 24px;
    }
}

h4,
.h4 {
    font-size: 28px;
    line-height: 1.25;

    @media ($below-mobile) {
        font-size: 20px;
    }
}

h5,
.h5 {
    font-size: 24px;
    line-height: 1.3;

    @media ($below-mobile) {
        font-size: 18px;
    }
}

h6,
.h6 {
    font-size: 18px;
    line-height: 1.4;

    @media ($below-mobile) {
        font-size: 14px;
    }
}

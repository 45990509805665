@font-face {
    font-family: "Ophian";
    src:
        local("Ophian"),
        url(../../public/fonts/Ophian-Regular-webfont.woff2) format("woff2"),
        local("Ophian"),
        url(../../public/fonts/Ophian-Regular-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-regular-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-blackitalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-blackitalic-webfont.woff) format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-black-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-black-webfont.woff) format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-heavyitalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-heavyitalic-webfont.woff) format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-heavy-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-heavy-webfont.woff) format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-bolditalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-bolditalic-webfont.woff) format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-bold-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-semibolditalic-webfont.woff2)
            format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-semibolditalic-webfont.woff) format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-semibold-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-semibold-webfont.woff) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-mediumitalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-mediumitalic-webfont.woff) format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-medium-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-medium-webfont.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-italic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-italic-webfont.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-lightitalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-lightitalic-webfont.woff) format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-light-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-light-webfont.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-thinitalic-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-thinitalic-webfont.woff) format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-thin-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-thin-webfont.woff) format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-hairlineitalic-webfont.woff2)
            format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-hairlineitalic-webfont.woff) format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src:
        local("Lato"),
        url(../../public/fonts/lato-hairline-webfont.woff2) format("woff2"),
        local("Lato"),
        url(../../public/fonts/lato-hairline-webfont.woff) format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

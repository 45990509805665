@import "../variables";
@import "./fonts";

:root {
    --color-black: #{#000000};
    --color-red: #{#922525};

    --font-base: "Lato";
    --font-secondary: "Ophian";

    --text-input-border-color: #{$pewter2};
}
